import React from 'react'
import styles from '../Forms.module.css'
import { Button, Divider, Row } from 'antd'
import { DeleteTwoTone } from '@ant-design/icons'
import {
  buffers,
  controls,
  getAvailableContainersType,
  plateLayout,
  tissueBlockSize,
} from 'services/helpers/constants'
import {
  getConcentrationRules,
  isBufferRequired,
  validateWellIsFree,
} from 'pages/OrderForm/helpers'
import { Sex } from 'services/interfaces'
import { SampleNameField } from '../Fields/SampleNameField'
import { SexField } from '../Fields/SexField'
import { ControlField } from '../Fields/ControlField'
import { ReqQCField } from '../Fields/ReqQcField'
import { ApplicationField } from '../Fields/ApplicationField'
import { SubjectIdField } from '../Fields/SubjectIdField'
import { AgeField } from '../Fields/AgeField'
import { PhenotypeGroupsField } from '../Fields/PhenotypeGroupsField'
import { PhenotypeTermsField } from '../Fields/PhenotypeTermsField'
import { CommentField } from '../Fields/CommentField'
import { ElutionBufferField } from '../Fields/ElutionBufferField'
import { SourceField } from '../Fields/SourceField'
import { FormalinFixationHoursField } from '../Fields/FormalinFixationHoursField'
import { WeeksAfterFixationField } from '../Fields/WeeksAfterFixationField'
import { TissueBlockField } from '../Fields/TissueBlockField'
import { SourceCommentField } from '../Fields/SourceCommentField'
import { ContainerField } from '../Fields/ContainerField'
import { ContainerNameField } from '../Fields/ContainerNameField'
import { VolumeField } from '../Fields/VolumeField'
import { WellPositionField } from '../Fields/WellPositionField'
import { ConcentrationField } from '../Fields/ConcentrationField'
import { DeletePopConfirm } from 'components/DeletePopConfirm'

export const MIPRNASampleForm = ({
  index,
  isExisting,
  setSampleId,
  analysisType,
  buildParentsObject,
  sampleId,
  setApplication,
  setApplicationObj,
  skipReceptionControl,
  userContext,
  applicationTags,
  form,
  caseName,
  subjectId,
  application,
  options,
  prefillConditionalFields,
  setContainerNameRequirement,
  hasContainer,
  containerNameRequirement,
  setContainerName,
  isPlate,
  isFormalineSource,
  containerName,
  source,
  applicationObj,
  isOtherSource,
  deleteSample,
}) => {
  return (
    <>
      <Row className={styles.row}>
        <SampleNameField
          index={index}
          isExisting={isExisting}
          setSampleId={setSampleId}
          buildParentsObject={null}
        />
        <SexField
          index={index}
          isExisting={isExisting}
          buildParentsObject={buildParentsObject}
          sampleId={sampleId}
          Sex={Sex}
        />
        <ControlField index={index} isExisting={isExisting} controls={controls} />
        {!isExisting && <ReqQCField index={index} isExisting={isExisting} />}
        {isExisting && (
          <>
            <ApplicationField
              index={index}
              isExisting={isExisting}
              setApplication={setApplication}
              setApplicationObj={setApplicationObj}
              skipReceptionControl={skipReceptionControl}
              userContext={userContext}
              applicationTags={applicationTags}
            ></ApplicationField>
          </>
        )}
      </Row>
      {!isExisting && (
        <>
          <Row className={styles.row}>
            <SubjectIdField
              isExisting={isExisting}
              subjectId={subjectId}
              caseName={form.getFieldValue('cases')[caseName]?.name}
              index={index}
              sampleId={sampleId}
              required={!isExisting}
            />
            <ApplicationField
              index={index}
              isExisting={isExisting}
              setApplication={setApplication}
              setApplicationObj={setApplicationObj}
              skipReceptionControl={skipReceptionControl}
              userContext={userContext}
              applicationTags={applicationTags}
            ></ApplicationField>
            <AgeField index={index} />
          </Row>
          <Row className={styles.row}>
            <PhenotypeGroupsField index={index} />
            <PhenotypeTermsField index={index} />
            <CommentField index={index} />
          </Row>
          <Divider style={{ marginTop: '-2px' }} />
          <Row className={styles.row}>
            <ElutionBufferField
              index={index}
              isBufferRequired={isBufferRequired}
              application={application}
              skipReceptionControl={skipReceptionControl}
              buffers={buffers}
              standalone={false}
            />
            <SourceField
              index={index}
              application={application}
              prefillConditionalFields={prefillConditionalFields}
              options={options}
            />
            {isFormalineSource && (
              <>
                <FormalinFixationHoursField index={index} />
                <WeeksAfterFixationField index={index} />
                <TissueBlockField index={index} tissueBlockSize={tissueBlockSize} />
              </>
            )}
            {isOtherSource && <SourceCommentField index={index} />}
          </Row>
          <Row className={styles.row}>
            <ContainerField
              index={index}
              prefillConditionalFields={prefillConditionalFields}
              setContainerNameRequirement={setContainerNameRequirement}
              getAvailableContainersType={getAvailableContainersType}
              analysisType={analysisType}
              required={true}
            />
            {hasContainer && (
              <>
                <ContainerNameField
                  index={index}
                  containerNameRequirement={containerNameRequirement}
                  setContainerName={setContainerName}
                  isRML={false}
                />{' '}
                <VolumeField index={index} />
              </>
            )}
            {isPlate && (
              <WellPositionField
                index={index}
                form={form}
                validateWellIsFree={validateWellIsFree}
                containerName={containerName}
                plateLayout={plateLayout}
                standalone={false}
                isRML={false}
              />
            )}
            <ConcentrationField
              index={index}
              skipReceptionControl={skipReceptionControl}
              applicationObj={applicationObj}
              getConcentrationRules={getConcentrationRules}
              source={source}
            />
          </Row>
        </>
      )}
      <DeletePopConfirm
        itemType={'sample'}
        action={deleteSample}
        itemName={caseName}
        triggerComponent={
          <Button icon={<DeleteTwoTone />} block>
            Sample
          </Button>
        }
      />
    </>
  )
}
