import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Card, Row, Alert, Divider } from 'antd'
import {
  buffers,
  getAvailableContainersType,
  controls,
  carrierStatus,
  tissueBlockSize,
  plateLayout,
  isBalsamic,
  balsamicQC,
  balsamicReferenceGenomes,
  isMIPDNA,
  isTomte,
  isMIPRNA,
} from '../../services/helpers/constants'
import { Parents, Sex } from '../../services/interfaces'
import { DeleteTwoTone } from '@ant-design/icons'
import styles from './Forms.module.css'
import {
  getConcentrationRules,
  getIsPlate,
  isBufferRequired,
  isPANPAZApplication,
  isSampleOld,
  validateBaitSet,
  validateWellIsFree,
} from '../../pages/OrderForm/helpers'
import { DeletePopConfirm } from '../DeletePopConfirm'
import { isNil } from 'ramda'
import dayjs from 'dayjs'
import { UserContext } from '../../services/contexts/userContext'
import { getApplication } from 'services/StatusDbApi'
import { ApplicationField } from './Fields/ApplicationField'
import { SubjectIdField } from './Fields/SubjectIdField'
import { SampleNameField } from './Fields/SampleNameField'
import { StatusField } from './Fields/StatusField'
import { TumourField } from './Fields/TumourField'
import { SexField } from './Fields/SexField'
import { ControlField } from './Fields/ControlField'
import { ReqQCField } from './Fields/ReqQcField'
import { MotherField } from './Fields/MotherField'
import { FatherField } from './Fields/FatherField'
import { ReferenceGenomeField } from './Fields/ReferenceGenomeField'
import { TumourPurityField } from './Fields/TumourPurityField'
import { BaitSetField } from './Fields/BaitSetField'
import { AgeField } from './Fields/AgeField'
import { PhenotypeGroupsField } from './Fields/PhenotypeGroupsField'
import { PhenotypeTermsField } from './Fields/PhenotypeTermsField'
import { CommentField } from './Fields/CommentField'
import { ElutionBufferField } from './Fields/ElutionBufferField'
import { SourceField } from './Fields/SourceField'
import { FormalinFixationHoursField } from './Fields/FormalinFixationHoursField'
import { WeeksAfterFixationField } from './Fields/WeeksAfterFixationField'
import { TissueBlockField } from './Fields/TissueBlockField'
import { SourceCommentField } from './Fields/SourceCommentField'
import { ContainerField } from './Fields/ContainerField'
import { ContainerNameField } from './Fields/ContainerNameField'
import { VolumeField } from './Fields/VolumeField'
import { WellPositionField } from './Fields/WellPositionField'
import { ConcentrationField } from './Fields/ConcentrationField'
import { BalsamicSampleForm } from './CaseSampleForms/BalsamicSampleForm'
import { MIPDNASampleForm } from './CaseSampleForms/MIPDNASampleForm'
import { TomteSampleForm } from './CaseSampleForms/TomteSampleForm'
import { MIPRNASampleForm } from './CaseSampleForms/MIPRNASampleForm'

export const CaseSampleForm = ({
  index,
  remove,
  options,
  form,
  caseName,
  parentsSamples,
  setParentSamples,
  analysisType,
  skipReceptionControl,
  applicationTags,
}) => {
  const [isFormalineSource, setIsFormalineSource] = useState(false)
  const [isOtherSource, setIsOtherSource] = useState(false)
  const [hasContainer, setHasContainer] = useState(false)
  const [isPlate, setIsPlate] = useState(false)
  const [isTumour, setIsTumour] = useState(false)
  const [containerName, setContainerName] = useState<string>()
  const [application, setApplication] = useState<string>()
  const [source, setSource] = useState<string>()
  const [sampleId, setSampleId] = useState<string>()
  const [isExisting, setIsExisting] = useState<boolean>(false)
  const [receivedAt, setReceivedAt] = useState<string>()
  const userContext = useContext(UserContext)
  const [applicationObj, setApplicationObj] = useState<any>()
  const [containerNameRequirement, setContainerNameRequirement] = useState<boolean>(false)
  const [subjectId, setSubjectId] = useState<string>()

  const deleteSample = () => {
    remove(index)
    prefillConditionalFields()
  }

  const buildParentsObject = (sampleId, key = index) => {
    const localSamples = form.getFieldValue('cases')[caseName]?.samples
    const localParents: Parents = { father: [], mother: [] }
    localSamples.forEach((sample) => {
      if (sample?.name !== sampleId) {
        if (sample?.sex === 'male') localParents.father.push({ name: sample?.name, id: key })
        else if (sample?.sex === 'female') localParents.mother.push({ name: sample?.name, id: key })
      }
    })
    setParentSamples(localParents)
  }

  const prefillConditionalFields = useCallback(() => {
    const {
      application,
      source,
      container,
      name,
      container_name,
      internal_id,
      tumour,
      received_at,
      subject_id,
    } = form.getFieldValue('cases')[caseName]?.samples[index] || ''
    setIsExisting(!isNil(internal_id))
    if (!isNil(internal_id)) setReceivedAt(dayjs(received_at).format('YYYY-MM-DD'))
    setSampleId(name)
    setIsTumour(tumour)
    setSource(source)
    setApplication(application)
    setSubjectId(subject_id)
    if (application && skipReceptionControl) {
      getApplication(userContext, application).then((res) => {
        setApplicationObj(res)
      })
    }
    setIsPlate(getIsPlate(container))
    setContainerName(container_name)
    buildParentsObject('')
    setHasContainer(!container?.includes('No container'))
    setContainerNameRequirement(container === '96 well plate')
    if (source) {
      setIsFormalineSource(source?.includes('FFPE'))
      setIsOtherSource(source?.includes('other'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, caseName, index, userContext])

  useEffect(() => {
    prefillConditionalFields()
  }, [prefillConditionalFields])

  return (
    <Card>
      {isExisting && (
        <>
          <Alert
            message="Existing sample. Erroneous values might delay analysis."
            type="info"
            showIcon
            style={{ marginBottom: 12 }}
          />
          {isSampleOld(receivedAt) && (
            <Alert
              message="You have placed an order of old data. Due to the legacy structure of this old data we will have to manually process it. We cannot guarantee fast delivery of such data - If time is of concern please consider asking us about possible re-sequencing of existing material or sending in a new aliquot."
              type="warning"
              showIcon
              style={{ marginBottom: 12 }}
            />
          )}
        </>
      )}
      {isBalsamic(analysisType) && (
        <BalsamicSampleForm
          index={index}
          isExisting={isExisting}
          setSampleId={setSampleId}
          analysisType={analysisType}
          buildParentsObject={buildParentsObject}
          setIsTumour={setIsTumour}
          sampleId={sampleId}
          setApplication={setApplication}
          setApplicationObj={setApplicationObj}
          skipReceptionControl={skipReceptionControl}
          userContext={userContext}
          applicationTags={applicationTags}
          form={form}
          caseName={caseName}
          subjectId={subjectId}
          isTumour={isTumour}
          application={application}
          options={options}
          prefillConditionalFields={prefillConditionalFields}
          setContainerNameRequirement={setContainerNameRequirement}
          hasContainer={hasContainer}
          containerNameRequirement={containerNameRequirement}
          setContainerName={setContainerName}
          isPlate={isPlate}
          isFormalineSource={isFormalineSource}
          containerName={containerName}
          source={source}
          applicationObj={applicationObj}
          isOtherSource={isOtherSource}
          deleteSample={deleteSample}
        />
      )}

      {isMIPDNA(analysisType) && (
        <MIPDNASampleForm
          index={index}
          isExisting={isExisting}
          setSampleId={setSampleId}
          analysisType={analysisType}
          buildParentsObject={buildParentsObject}
          sampleId={sampleId}
          setApplication={setApplication}
          setApplicationObj={setApplicationObj}
          skipReceptionControl={skipReceptionControl}
          userContext={userContext}
          applicationTags={applicationTags}
          form={form}
          caseName={caseName}
          subjectId={subjectId}
          application={application}
          options={options}
          prefillConditionalFields={prefillConditionalFields}
          setContainerNameRequirement={setContainerNameRequirement}
          hasContainer={hasContainer}
          containerNameRequirement={containerNameRequirement}
          setContainerName={setContainerName}
          isPlate={isPlate}
          isFormalineSource={isFormalineSource}
          containerName={containerName}
          source={source}
          applicationObj={applicationObj}
          isOtherSource={isOtherSource}
          deleteSample={deleteSample}
          parentsSamples={parentsSamples}
        />
      )}

      {isTomte(analysisType) && (
        <TomteSampleForm
          index={index}
          isExisting={isExisting}
          setSampleId={setSampleId}
          analysisType={analysisType}
          buildParentsObject={buildParentsObject}
          sampleId={sampleId}
          setApplication={setApplication}
          setApplicationObj={setApplicationObj}
          skipReceptionControl={skipReceptionControl}
          userContext={userContext}
          applicationTags={applicationTags}
          form={form}
          caseName={caseName}
          subjectId={subjectId}
          application={application}
          options={options}
          prefillConditionalFields={prefillConditionalFields}
          setContainerNameRequirement={setContainerNameRequirement}
          hasContainer={hasContainer}
          containerNameRequirement={containerNameRequirement}
          setContainerName={setContainerName}
          isPlate={isPlate}
          isFormalineSource={isFormalineSource}
          containerName={containerName}
          source={source}
          applicationObj={applicationObj}
          isOtherSource={isOtherSource}
          deleteSample={deleteSample}
          parentsSamples={parentsSamples}
        />
      )}

      {isMIPRNA(analysisType) && (
        <MIPRNASampleForm
          index={index}
          isExisting={isExisting}
          setSampleId={setSampleId}
          analysisType={analysisType}
          buildParentsObject={buildParentsObject}
          sampleId={sampleId}
          setApplication={setApplication}
          setApplicationObj={setApplicationObj}
          skipReceptionControl={skipReceptionControl}
          userContext={userContext}
          applicationTags={applicationTags}
          form={form}
          caseName={caseName}
          subjectId={subjectId}
          application={application}
          options={options}
          prefillConditionalFields={prefillConditionalFields}
          setContainerNameRequirement={setContainerNameRequirement}
          hasContainer={hasContainer}
          containerNameRequirement={containerNameRequirement}
          setContainerName={setContainerName}
          isPlate={isPlate}
          isFormalineSource={isFormalineSource}
          containerName={containerName}
          source={source}
          applicationObj={applicationObj}
          isOtherSource={isOtherSource}
          deleteSample={deleteSample}
        />
      )}
      {/* <Row className={styles.row}>
        <SampleNameField
          index={index}
          isExisting={isExisting}
          setSampleId={setSampleId}
          isMIPDNA={isMIPDNA}
          isTomte={isTomte}
          analysisType={analysisType}
          buildParentsObject={buildParentsObject}
        />
        {(isMIPDNA(analysisType) || isTomte(analysisType)) && (
          <StatusField isExisting={isExisting} index={index} carrierStatus={carrierStatus} />
        )}
        {isBalsamic(analysisType) && (
          <TumourField index={index} setIsTumour={setIsTumour} isExisting={isExisting} />
        )}
        <SexField
          index={index}
          isExisting={isExisting}
          buildParentsObject={buildParentsObject}
          sampleId={sampleId}
          Sex={Sex}
        />
        <ControlField index={index} isExisting={isExisting} controls={controls} />
        {!isExisting && <ReqQCField index={index} isExisting={isExisting} />}
        {isExisting && (
          <>
            <ApplicationField
              index={index}
              isExisting={isExisting}
              setApplication={setApplication}
              setApplicationObj={setApplicationObj}
              skipReceptionControl={skipReceptionControl}
              userContext={userContext}
              applicationTags={applicationTags}
            ></ApplicationField>
            <SubjectIdField
              isExisting={isExisting}
              caseName={form.getFieldValue('cases')[caseName]?.name}
              subjectId={subjectId}
              required={!isExisting}
              index={index}
              sampleId={sampleId}
            />
          </>
        )}
      </Row>
      {(isMIPDNA(analysisType) || isTomte(analysisType)) && (
        <Row className={styles.row}>
          <MotherField index={index} parentsSamples={parentsSamples} sampleId={sampleId} />
          <FatherField index={index} parentsSamples={parentsSamples} sampleId={sampleId} />
          {isTomte(analysisType) && (
            <ReferenceGenomeField
              index={index}
              isExisting={isExisting}
              balsamicReferenceGenomes={balsamicReferenceGenomes}
            />
          )}
        </Row>
      )}

      {isBalsamic(analysisType) && (
        <Row className={styles.row}>
          {analysisType === balsamicQC && (
            <ReferenceGenomeField
              index={index}
              isExisting={isExisting}
              balsamicReferenceGenomes={balsamicReferenceGenomes}
            />
          )}
          {isTumour && !isExisting && <TumourPurityField index={index} isExisting={isExisting} />}
          {isPANPAZApplication(application) && (
            <BaitSetField
              index={index}
              isExisting={isExisting}
              validateBaitSet={validateBaitSet}
              options={options}
            />
          )}
        </Row>
      )}
      {!isExisting && (
        <>
          <Row className={styles.row}>
            <SubjectIdField
              isExisting={isExisting}
              subjectId={subjectId}
              caseName={form.getFieldValue('cases')[caseName]?.name}
              index={index}
              sampleId={sampleId}
              required={!isExisting}
            />
            <ApplicationField
              index={index}
              isExisting={isExisting}
              setApplication={setApplication}
              setApplicationObj={setApplicationObj}
              skipReceptionControl={skipReceptionControl}
              userContext={userContext}
              applicationTags={applicationTags}
            ></ApplicationField>
            <AgeField index={index} />
          </Row>
          <Row className={styles.row}>
            <PhenotypeGroupsField index={index} />
            <PhenotypeTermsField index={index} />
            <CommentField index={index} />
          </Row>
          <Divider style={{ marginTop: '-2px' }} />
          <Row className={styles.row}>
            <ElutionBufferField
              index={index}
              isBufferRequired={isBufferRequired}
              application={application}
              skipReceptionControl={skipReceptionControl}
              buffers={buffers}
              standalone={false}
            />
            <SourceField
              index={index}
              application={application}
              prefillConditionalFields={prefillConditionalFields}
              options={options}
            />
            {isFormalineSource && (
              <>
                <FormalinFixationHoursField index={index} />
                <WeeksAfterFixationField index={index} />
                <TissueBlockField index={index} tissueBlockSize={tissueBlockSize} />
              </>
            )}
            {isOtherSource && <SourceCommentField index={index} />}
          </Row>
          <Row className={styles.row}>
            <ContainerField
              index={index}
              prefillConditionalFields={prefillConditionalFields}
              setContainerNameRequirement={setContainerNameRequirement}
              getAvailableContainersType={getAvailableContainersType}
              analysisType={analysisType}
              required={true}
            />
            {hasContainer && (
              <>
                <ContainerNameField
                  index={index}
                  containerNameRequirement={containerNameRequirement}
                  setContainerName={setContainerName}
                  isRML={false}
                />{' '}
                <VolumeField index={index} />
              </>
            )}
            {isPlate && (
              <WellPositionField
                index={index}
                form={form}
                validateWellIsFree={validateWellIsFree}
                containerName={containerName}
                plateLayout={plateLayout}
                standalone={false}
                isRML={false}
              />
            )}
            <ConcentrationField
              index={index}
              skipReceptionControl={skipReceptionControl}
              applicationObj={applicationObj}
              getConcentrationRules={getConcentrationRules}
              source={source}
            />
          </Row>
        </>
      )}
      <DeletePopConfirm
        itemType={'sample'}
        action={deleteSample}
        itemName={caseName}
        triggerComponent={
          <Button icon={<DeleteTwoTone />} block>
            Sample
          </Button>
        }
      /> */}
    </Card>
  )
}
