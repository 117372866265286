import React from 'react'
import { CarrierStatus } from '../interfaces'

export const acceptedCookieName = 'acceptedCookiePolicy'

export const language = 'en-US'

export const mipDNA = 'mip-dna'
export const mipRNA = 'mip-rna'
export const rnaFusion = 'rnafusion'
export const balsamic = 'balsamic'
export const balsamicUMI = 'balsamic-umi'
export const balsamicQC = 'balsamic-qc'
export const sarsCovid = 'sars-cov-2'
export const tomte = 'tomte'
export const clinical = 'fastq'
export const rawData = 'raw-data'
export const microbialWGS = 'microsalt'
export const fluffy = 'fluffy'
export const metagenomeWGS = 'metagenome'
export const rml = 'rml'

export const isMIP = (analysisType: string) => analysisType.includes('mip')
export const isMIPDNA = (analysisType: string) => analysisType.includes(mipDNA)
export const isRNA = (analysisType: string) => analysisType.includes('rna')
export const isMIPRNA = (analysisType: string) => analysisType.includes(mipRNA)
export const isRNAFusion = (analysisType: string) => analysisType.includes(rnaFusion)
export const isBalsamic = (analysisType: string) => analysisType.includes(balsamic)
export const isSarsCovid = (analysisType: string) => analysisType.includes(sarsCovid)
export const isTomte = (analysisType: string) => analysisType.includes(tomte)
export const isMicrobialWGS = (analysisType: string) =>
  analysisType.toLowerCase().includes(microbialWGS)
export const isMetagenome = (analysisType: string) => analysisType.includes(metagenomeWGS)
export const isClinical = (analysisType: string) => analysisType === clinical
export const isTaxprofiler = (analysisType: string) => analysisType === 'taxprofiler'
export const isStandaloneSampleForm = (analysisType: string) =>
  !isBalsamic(analysisType) &&
  !isMIP(analysisType) &&
  !isRNA(analysisType) &&
  !isTomte(analysisType)
export const isRML = (analysisType: string) =>
  analysisType.includes(fluffy) || analysisType.includes(rml)
export const isMicrobialFastq = (analysisType: string) => analysisType === 'microbial-fastq'

export const orderTypes = [
  {
    text: 'Balsamic analysis (Sequencing/Existing data)', // done
    orderType: balsamic,
    dataAnalysis: balsamic,
  },
  {
    text: 'Balsamic and UMI analysis (Sequencing/Existing data)', // done
    orderType: balsamicUMI,
    dataAnalysis: balsamicUMI,
  },
  {
    text: 'Balsamic QC (Sequencing/Existing data)', // done
    orderType: balsamicQC,
    dataAnalysis: balsamicQC,
  },
  {
    text: 'MIP analysis (Sequencing/Existing data)', // done
    orderType: mipDNA,
    dataAnalysis: mipDNA,
  },
  {
    text: 'MIP RNA analysis (Sequencing/Existing data)', // case form done
    orderType: mipRNA,
    dataAnalysis: mipRNA,
  },
  {
    text: 'Metagenome WGS', // done
    orderType: metagenomeWGS,
    dataAnalysis: rawData,
  },
  {
    text: 'RNA fusion', // case form done
    orderType: rnaFusion,
    dataAnalysis: rnaFusion,
  },
  {
    text: 'SARS CoV-2', // done
    orderType: sarsCovid,
    dataAnalysis: 'mutant',
  },
  {
    text: 'Sequencing of clinical samples', // done
    orderType: clinical,
    dataAnalysis: rawData,
  },
  {
    text: 'Sequencing of microbial samples', // done
    orderType: 'microbial-fastq',
    dataAnalysis: rawData,
  },
  {
    text: 'Sequencing of ready-made libraries', // done
    orderType: rml,
    dataAnalysis: rawData,
  },
  {
    text: 'Sequencing of RML + Fluffy analysis', // done
    orderType: fluffy,
    dataAnalysis: fluffy,
  },
  { text: 'Taxprofiler analysis', orderType: 'taxprofiler', dataAnalysis: 'taxprofiler' }, // done
  {
    text: 'Tomte', // done
    orderType: tomte,
    dataAnalysis: tomte,
  },
  {
    text: 'Typing of microbial isolates', // done
    orderType: microbialWGS,
    dataAnalysis: microbialWGS,
  },
]

export const deliveryOptions = {
  analysis: { text: 'Analysis files', value: 'analysis' },
  fastq: { text: 'Raw sequencing data (fastq)', value: 'fastq' },
  fastq_scout: { text: 'fastq files and scout upload', value: 'fastq-scout' },
  fastq_qc: { text: 'Fastq files with quality report', value: 'fastq_qc' },
  fastq_qc_analysis: { text: 'Fastq QC + Analysis', value: 'fastq_qc-analysis' },
  fastq_analysis: { text: 'Fastq + Analysis', value: 'fastq-analysis' },
  fastq_analysis_scout: { text: 'Fastq + Analysis + Scout', value: 'fastq-analysis-scout' },
  analysis_scout: { text: 'Analysis + Scout', value: 'analysis-scout' },
  scout: { text: 'Uploaded in Scout-browser', value: 'scout' },
  statina: { text: 'Statina', value: 'statina' },
  no_delivery: { text: 'No Delivery', value: 'no-delivery' },
}

export const allowedDeliveryOptions: Record<string, { value: string; text: string }[]> = {
  metagenome: [deliveryOptions.fastq, deliveryOptions.no_delivery],
  fastq: [deliveryOptions.fastq, deliveryOptions.no_delivery],
  'microbial-fastq': [deliveryOptions.fastq, deliveryOptions.no_delivery],
  rml: [deliveryOptions.fastq, deliveryOptions.no_delivery],
  fluffy: [deliveryOptions.statina, deliveryOptions.no_delivery],
  microsalt: [
    deliveryOptions.fastq_qc,
    deliveryOptions.fastq_qc_analysis,
    deliveryOptions.no_delivery,
  ],
  'sars-cov-2': [deliveryOptions.fastq, deliveryOptions.no_delivery],
  balsamic: [
    deliveryOptions.analysis,
    deliveryOptions.analysis_scout,
    deliveryOptions.scout,
    deliveryOptions.fastq_analysis,
    deliveryOptions.fastq_scout,
    deliveryOptions.fastq_analysis_scout,
    deliveryOptions.no_delivery,
  ],
  'balsamic-umi': [
    deliveryOptions.analysis,
    deliveryOptions.analysis_scout,
    deliveryOptions.scout,
    deliveryOptions.fastq_analysis,
    deliveryOptions.fastq_scout,
    deliveryOptions.fastq_analysis_scout,
    deliveryOptions.no_delivery,
  ],
  'balsamic-qc': [
    deliveryOptions.analysis,
    deliveryOptions.fastq_analysis,
    deliveryOptions.no_delivery,
  ],
  'mip-dna': [
    deliveryOptions.analysis,
    deliveryOptions.analysis_scout,
    deliveryOptions.scout,
    deliveryOptions.fastq_analysis,
    deliveryOptions.fastq_scout,
    deliveryOptions.fastq_analysis_scout,
    deliveryOptions.no_delivery,
  ],
  'mip-rna': [
    deliveryOptions.analysis,
    deliveryOptions.analysis_scout,
    deliveryOptions.scout,
    deliveryOptions.fastq,
    deliveryOptions.fastq_analysis,
    deliveryOptions.fastq_scout,
    deliveryOptions.fastq_analysis_scout,
    deliveryOptions.no_delivery,
  ],
  rnafusion: [
    deliveryOptions.analysis,
    deliveryOptions.analysis_scout,
    deliveryOptions.scout,
    deliveryOptions.fastq,
    deliveryOptions.fastq_analysis,
    deliveryOptions.fastq_scout,
    deliveryOptions.fastq_analysis_scout,
    deliveryOptions.no_delivery,
  ],
  taxprofiler: [
    deliveryOptions.analysis,
    deliveryOptions.fastq_analysis,
    deliveryOptions.no_delivery,
  ],
  tomte: [
    deliveryOptions.analysis,
    deliveryOptions.analysis_scout,
    deliveryOptions.scout,
    deliveryOptions.fastq,
    deliveryOptions.fastq_analysis,
    deliveryOptions.fastq_scout,
    deliveryOptions.fastq_analysis_scout,
    deliveryOptions.no_delivery,
  ],
}

export const controls = ['', 'negative', 'positive']

export const priorities = [
  { value: 'research', text: 'Research', tagColor: 'blue' },
  { value: 'standard', text: 'Standard', tagColor: 'cyan' },
  { value: 'priority', text: 'Priority', tagColor: 'volcano' },
  { value: 'express', text: 'Express', tagColor: 'magenta' },
  { value: 'clinical_trials', text: 'Clinical trial', tagColor: 'green' },
]

export const sexes = ['female', 'male', 'unknown']

export const carrierStatus: CarrierStatus[] = [
  { status: 'unknown', badgeColor: 'default' },
  { status: 'affected', badgeColor: 'warning' },
  { status: 'unaffected', badgeColor: 'success' },
]

export const tissueBlockSize = ['large', 'small']

export const allContainerTypes = ['No container', 'Tube', '96 well plate']

export const restrictedContainers = ['Tube', '96 well plate']

const restrictedAnalysisTypes = ['microsalt', 'microbial-fastq']

export const getAvailableContainersType = (analysisType: string): string[] => {
  return restrictedAnalysisTypes.includes(analysisType) ? restrictedContainers : allContainerTypes
}

export const buffers = ['Nuclease-free water', 'Tris-HCl', 'Other (add to comment)']

const plateVerticalWells = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
const plateHorizontalWells = 12

export const plateLayout = (
  verticalWells = plateVerticalWells,
  horizontalWells = plateHorizontalWells
) => {
  const wellsLayout: string[] = []
  Array.from(Array(horizontalWells).keys()).map((i) => {
    verticalWells.map((letter) => {
      wellsLayout.push(`${letter}:${i + 1}`)
    })
  })
  return wellsLayout
}

export const supportSystemURL = 'https://clinical-scilifelab.supportsystem.com/'

export const balsamicReferenceGenomes = [
  { text: 'Human hg19', value: 'hg19' },
  { text: 'Human hg38', value: 'hg38' },
  { text: 'Dog canfam3', value: 'canfam3' },
]

export const extractionMethods = [
  'EZ1',
  'Maelstrom',
  'MagNaPure 96 (contact Clinical Genomics before submission)',
  'Qiagen MagAttract',
  'QIAsymphony',
  'Other (specify in "Comments")',
]

export const preprocessingMethods = [
  'COVIDSeq',
  'Qiagen SARS-CoV-2 Primer Panel',
  'Other (specify in "Comments")',
]

export const primers = ['Illumina Artic V3', 'Nanopore Midnight V1']

export const regions = [
  'Stockholm',
  'Uppsala',
  'Sörmland',
  'Östergötland',
  'Jönköpings län',
  'Kronoberg',
  'Kalmar län',
  'Gotland',
  'Blekinge',
  'Skåne',
  'Halland',
  'Västra Götalandsregionen',
  'Värmland',
  'Örebro län',
  'Västmanland',
  'Dalarna',
  'Gävleborg',
  'Västernorrland',
  'Jämtland Härjedalen',
  'Västerbotten',
  'Norrbotten',
]

export const regions1 = [{ code: 1, area: 'Stockholm' }]

export const selectionCriteria = [
  'Allmän övervakning',
  'Allmän övervakning öppenvård',
  'Allmän övervakning slutenvård',
  'Utlandsvistelse',
  'Riktad insamling',
  'Utbrott',
  'Vaccinationsgenombrott',
  'Reinfektion',
  'Information saknas',
]

export const labs = [
  'Unilabs Stockholm',
  'Unilabs Eskilstuna Laboratorium',
  'Norrland University Hospital',
  'Länssjukhuset Sundsvall',
  'A05 Diagnostics',
  'Synlab Medilab',
  'Karolinska University Hospital Solna',
  'Karolinska University Hospital Huddinge',
  'LaboratorieMedicinskt Centrum Gotland',
]

export const labAddress = [
  ['Unilabs Stockholm', '171 54 Solna'],
  ['Unilabs Eskilstuna Laboratorium', '631 88 Eskilstuna'],
  ['Norrland University Hospital', '901 85 Umeå'],
  ['Länssjukhuset Sundsvall', '856 43 Sundsvall'],
  ['Synlab Medilab', '183 53 Täby'],
  ['A05 Diagnostics', '171 65 Solna'],
  ['Karolinska University Hospital Solna', '171 76 Stockholm'],
  ['Karolinska University Hospital Huddinge', '141 86 Stockholm'],
  ['LaboratorieMedicinskt Centrum Gotland', '621 84 Visby'],
]

export const labCodes = [
  'SE110 Växjö',
  'SE120 Malmö',
  'SE240 Kalmar',
  'SE320 Borås',
  'SE450 Karlstad',
  'SE250 Halmstad',
  'SE310 Trollhättan NÄL',
  'SE300 Sahlgrenska',
  'SE230 Karlskrona',
  'SE540 Visby',
  'SE100 Karolinska',
  'SE130 Unilabs',
  'SE140 SYNLAB',
  'SE330 Unilabs',
  'SE350 Jönköping',
  'SE400 Linköping',
  'SE420 Unilabs',
  'SE430 Västerås',
  'SE440 Örebro',
  'SE600 Uppsala',
  'SE610 Gävle',
  'SE620 Falun',
  'SE700 Sundsvall',
  'SE710 Östersund',
  'SE720 Umeå',
  'SE730 Sunderby, Luleå',
  'SENPC National Pandemic Center',
]
