import React from 'react'
import { Divider, Row } from 'antd'
import styles from '../Forms.module.css'
import { SampleNameField } from '../Fields/SampleNameField'
import { PriorityField } from '../Fields/PriorityField'
import { SaveButton } from '../Fields/SaveButton'
import { ReqQCField } from '../Fields/ReqQcField'
import { ApplicationField } from '../Fields/ApplicationField'
import { SexField } from '../Fields/SexField'
import { Sex } from 'services/interfaces'
import { TumourField } from '../Fields/TumourField'
import { SubjectIdField } from '../Fields/SubjectIdField'
import { CommentField } from '../Fields/CommentField'
import { ElutionBufferField } from '../Fields/ElutionBufferField'
import { SourceField } from '../Fields/SourceField'
import { ContainerField } from '../Fields/ContainerField'
import { ContainerNameField } from '../Fields/ContainerNameField'
import { WellPositionField } from '../Fields/WellPositionField'
import { VolumeField } from '../Fields/VolumeField'
import { QuantityNGField } from '../Fields/QuantityNGField'
import { DeleteButton } from '../Fields/DeleteButton'
import { ConcentrationField } from '../Fields/ConcentrationField'
import { SourceCommentField } from '../Fields/SourceCommentField'
import { isPANPAZApplication, validateBaitSet } from 'pages/OrderForm/helpers'
import { BaitSetField } from '../Fields/BaitSetField'

export const ClinicalSamplesOrder = ({
  index,
  setSampleId,
  analysisType,
  priorities,
  validateSample,
  setApplication,
  setApplicationObj,
  skipReceptionControl,
  userContext,
  applicationTags,
  form,
  buffers,
  prefillConditionalFields,
  setContainerNameRequirement,
  getAvailableContainersType,
  hasContainer,
  containerNameRequirement,
  setContainerName,
  isPlate,
  containerName,
  plateLayout,
  remove,
  sampleId,
  buildParentsObject,
  setIsTumour,
  subjectId, // check this why setSubjectId is not used
  isBufferRequired,
  application,
  options,
  validateWellIsFree,
  applicationObj,
  getConcentrationRules,
  source,
  isOtherSource,
}) => {
  return (
    <>
      <Row className={styles.row}>
        <SampleNameField
          index={index}
          isExisting={null}
          setSampleId={setSampleId}
          buildParentsObject={null}
        />
        <PriorityField index={index} priorities={priorities} isExisting={null} />
        <SaveButton saveValidate={validateSample} />
        <ReqQCField index={index} isExisting={null} />
      </Row>
      <Row className={styles.row}>
        <ApplicationField
          index={index}
          isExisting={null}
          setApplication={setApplication}
          setApplicationObj={setApplicationObj}
          skipReceptionControl={skipReceptionControl}
          userContext={userContext}
          applicationTags={applicationTags}
        />
        {isPANPAZApplication(application) && (
          <BaitSetField
            index={index}
            isExisting={null}
            validateBaitSet={validateBaitSet}
            options={options}
          />
        )}
        <SexField
          index={index}
          isExisting={null}
          buildParentsObject={buildParentsObject}
          sampleId={sampleId}
          Sex={Sex}
        />
        <TumourField index={index} setIsTumour={setIsTumour} isExisting={null} />
        <SubjectIdField
          caseName={sampleId + 'fam'}
          sampleId={sampleId}
          subjectId={subjectId}
          required={false}
          index={index}
          isExisting={null}
        />
      </Row>
      <Row className={styles.row}>
        <CommentField index={index} />
      </Row>
      <Divider style={{ marginTop: '-2px' }} />
      <ElutionBufferField
        index={index}
        isBufferRequired={isBufferRequired}
        application={null}
        skipReceptionControl={null}
        buffers={buffers}
        standalone={true}
      />
      <Row className={styles.row}>
        <SourceField
          index={index}
          application={application}
          prefillConditionalFields={prefillConditionalFields}
          options={options}
        />
        {isOtherSource && <SourceCommentField index={index} />}
      </Row>
      <Row className={styles.row}>
        <ContainerField
          index={index}
          prefillConditionalFields={prefillConditionalFields}
          setContainerNameRequirement={setContainerNameRequirement}
          getAvailableContainersType={getAvailableContainersType}
          analysisType={analysisType}
          required={true}
        />
        {hasContainer && (
          <>
            <ContainerNameField
              index={index}
              containerNameRequirement={containerNameRequirement}
              setContainerName={setContainerName}
              isRML={false}
            />
            {isPlate && (
              <WellPositionField
                index={index}
                form={form}
                validateWellIsFree={validateWellIsFree}
                containerName={containerName}
                plateLayout={plateLayout}
                standalone={true}
                isRML={false}
              />
            )}
            <VolumeField index={index} />
            <QuantityNGField index={index} />
            <ConcentrationField
              index={index}
              skipReceptionControl={skipReceptionControl}
              applicationObj={applicationObj}
              getConcentrationRules={getConcentrationRules}
              source={source}
            />
          </>
        )}
      </Row>
      <DeleteButton remove={remove} index={index} sampleId={sampleId} />
    </>
  )
}
