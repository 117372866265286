import React from 'react'
import { Form, Select } from 'antd'

export const PriorityField = ({ index, priorities, isExisting }) => {
  // test the require is working in both case and standalone
  return (
    <Form.Item
      label="Priority"
      rules={[{ required: true && !isExisting }]}
      required={!isExisting}
      name={[index, 'priority']}
    >
      <Select disabled={isExisting} style={{ width: 120 }}>
        {priorities.map(({ value, text }) => (
          <Select.Option key={value} value={value}>
            {text}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
